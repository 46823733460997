import React from "react"
import './founder.scss'

// Dieser Komponent soll sich um die Logik kümmern wie die Projekte gerendert werden
const ListItemDateText = ({list, title}) => (
    <div className="founder_exhibitions">
        <div className="founder_list_title">
            <p>{title}</p>
        </div>
        {
            list ? list.map((item, i) => (
                <p key={i} className="list_items">
                    <span dangerouslySetInnerHTML={{ __html: item.date }} />
                    <span dangerouslySetInnerHTML={{ __html: item.text }} />
                </p>
            )) : false
        }
    </div>
)

// const Founder = ({ text, title, exhibitions, teaching, publications, contributers, contributer1, contributer2, abouttext3 }) => {
const Founder = ({ content }) => {

    const {
        founder,
        title,
        exhibitions,
        teaching,
        publications,
        contributers,
        contributer1,
        contributer2,
        abouttext2,
        abouttext3,
        press,
        abouttext
    } = content
    console.log(abouttext);
return  (
    <div className="founder_body"> 
        <div>
                <div className="founder__tag">
                    <h2 dangerouslySetInnerHTML={{ __html: title }} />
                </div>
                <div className="founder_text">
                <p>
                    <span className="contributer_name">Gillian Shaffer Lutsko </span>
                    <span dangerouslySetInnerHTML={{ __html: founder }} />
                </p>
                </div>
                <div className="founder_list">

                    <ListItemDateText title="Recent Press" list={press} />

                    {/* <ListItemDateText title="Academia" list={teaching} />
                    
                    <div className="founder_additionals">
                    {
                            teaching ? teaching.map((teaching, i) => (
                                <p key={i}>
                                    <span dangerouslySetInnerHTML={{ __html: teaching.additionals }} />
                                </p>
                            )) : false
                        }
                    </div>
                    
                    <ListItemDateText title="Publications" list={publications} /> */}
            </div>
    </div>

                <div className="contributer_body">
                    <div className="contributer_tag">
                        <p>CONTRIBUTERS</p>
                    </div>

                        <div className="contributer_text">
                        <p>
                        <span className="contributer_name">Sandra Junghanns </span>
                        <span dangerouslySetInnerHTML={{ __html: abouttext }} />
                        </p>
                        </div>
                    
                    <div className="founder_list_right">
                    <ListItemDateText title="" list={contributer1} />
                    </div>

                    <div className="contributer_text">
                        <p>
                            <span className="contributer_name">Nick Lutsko </span>
                            <span dangerouslySetInnerHTML={{ __html: abouttext2 }} />
                        </p>
                    </div>

                    <div className="founder_list_right">
                    <ListItemDateText title="" list={contributer2} />
                    </div>

                    <div className="contributer_text">
                        <p>
                            <span dangerouslySetInnerHTML={{ __html: abouttext3 }} />
                        </p>
                    </div>

                </div>
            </div>

)
}

export default Founder