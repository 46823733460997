import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import IntroText from "../components/sections/introText"
import IntroSubtext from "../components/sections/introSubtext"
import Founder from "../components/sections/founder"
import Imprint from "../components/sections/imprint"

const AboutPage = ({ data }) => {
  const content = data.file.childMarkdownRemark.frontmatter;
  const intro = data.file.childMarkdownRemark.frontmatter.intro;
  const suptext = data.file.childMarkdownRemark.frontmatter.suptext;

  return(
  <Layout>
      <SEO title="About" />
      <IntroText text={intro}/>
      <IntroSubtext text={suptext}/>
      <Founder content={content}/>
      <Imprint/>
  </Layout>
)}

export default AboutPage

// GraphQL Grob erklärt:
// Durchsuche all Files FIlter nach Typ »content« und nache Name »About«
// Es kann nur ein File mit diesem Namen geben, daher handelt es sich her nicht um ein Array

export const aboutQuery = graphql`
query aboutQuery {
  file(sourceInstanceName: {eq: "content"}, name: {eq: "about"}) {
    id
    childMarkdownRemark {
      frontmatter {
        title
        intro
        text
        founder
        exhibitions {
          text
          date
        }
        contributer1 {
          date
          text
          title
        }
        contributer2 {
          date
          text
        }
        contributers {
          text
          name
        }
        infos {
          text
          title
        }
        abouttext
        abouttext2
        abouttext3
        publications {
          date
          text
        }
        suptext
        teaching {
          additionals
          date
          text
        }
        press {
          date
          text
        }
      }
    }
  }
}
`


// query contactQuery {
//   allFile(filter: {relativeDirectory: {eq: "about"}}) {
//     nodes {
//       childMarkdownRemark {
//         frontmatter {
//           title
//           text
//           images {
//             image
//           }
//           infobox {
//             label
//             text
//           }
//           tags {
//             tag
//           }
//         }
//       }
//     }
//   }
// }



// allFile(filter: {sourceInstanceName: {eq: "content"}, name: {eq: "about"}}) {
//   edges {
//     node {
//       id
//       childMarkdownRemark {
//         frontmatter {
//           title
//         }
//       }
//     }
//   }
// }