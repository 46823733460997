import React from "react"
import './imprint.scss'

const Imprint = () => (
    <div className="imprint">
        <p className="founder__tag">IMPRINT</p>
        <p>© {new Date().getFullYear()} sl Collective, all rights reserved.</p>
        <p>Design + Development: Burrow</p>
        {/* <Image /> */}
    </div>     
)

export default Imprint